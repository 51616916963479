import {
  CHANGE_MY_PROVIDER,
  FETCH_MY_PROVIDER_REQUEST,
  FETCH_MY_PROVIDER_SUCCESS,
  FETCH_MY_PROVIDER_FAILURE,
  SET_MY_PROVIDER,
} from "./actionType";

const INIT_STATE = {
  data: null,
  isLoading: false,
  error: null,
};

const MyProvider = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_MY_PROVIDER:
      return { ...state, data: action.payload };

    case SET_MY_PROVIDER:
      return { ...state, data: action.payload };

    case FETCH_MY_PROVIDER_REQUEST:
      return { ...state, isLoading: true, error: null };

    case FETCH_MY_PROVIDER_SUCCESS:
      return { ...state, isLoading: false, error: null };

    case FETCH_MY_PROVIDER_FAILURE:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return state;
  }
};

export default MyProvider;
