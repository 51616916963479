import React, { useEffect, useState } from "react";
import Loader from "./Common/Loader";
import "../styles/market.css";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchPeakTimeRateTrends } from "../api/usnMarket";
import { MyBarChart } from "./MyBarChart";
import { monthToType } from "../api/common";
import { Auth } from "aws-amplify";
import { changeGetAccessModalDisplay } from "../store/actions";
import DataTable from "react-data-table-component";
import { selectUtility } from "../store/utility/selector";
import MyTooltip from "./Common/Tooltip";
import { Serie } from "@nivo/line";
import moment from "moment";

const colors: any = {
  on_peak: "#37A2DA",
  off_peak: "#67E0E3",
  mid_peak: "#FFDB5C",
  super_off_peak: "#fb7293",
};

const USN_PeaktimeRateTrends = () => {
  const dispatch = useDispatch();
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);

  const [range, setRange] = useState("12");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Serie[]>([]);
  const [dataSource, setDataSource] = useState<any>(null);
  const [isLogin, setIsLogin] = useState(false);
  const currentUtility = useSelector(selectUtility);

  useEffect(() => {
    const checkAuth = async () => {
      const user = await Auth.currentAuthenticatedUser();
      if (user) setIsLogin(true);
    };

    checkAuth();
  }, [Auth]);

  const getData = async () => {
    setIsLoading(true);

    const requestData = {
      state_id: currentLocation?.state_id,
      utility_id: currentUtility.utility_id,
      type: monthToType(range),
    };

    const res = await fetchPeakTimeRateTrends(...Object.values(requestData));

    if (res?.status === 1) {
      const response = res.response || [];

      const allKeys =
        response[0] && Object.keys(response[0]).filter((key) => key !== "date");

      const validKeys = allKeys.filter((key: string) =>
        response.some((entry: any) => entry[key] !== 0)
      );

      const formattedDate = validKeys.map((key: string) => ({
        id:
          key
            ?.split("_")
            .map((word: string) => word[0].toUpperCase() + word.slice(1))
            .join(" ") || "",
        color: colors[key],
        data: response.map((entry: any) => ({
          x: moment(entry.date, "YYYY-MM").format("MMM YYYY"),
          y: entry[key],
        })),
      }));

      setData(formattedDate);

      const { data_source, peak_source, dalculation_formulas, peak_desc } =
        res || {};
      setDataSource({
        calculation_formulas: dalculation_formulas || null,
        data_source: data_source || [],
        peak_source: peak_source || [],
        peak_desc: peak_desc || {},
      });
    } else {
      setData([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!currentUtility) return;
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, currentUtility]);

  // const checkAuthAndSetValue = async (val: string) => {
  //   if (val !== "3") {
  //     const auth = await Auth.currentUserInfo();
  //     if (auth) {
  //       setRange(val);
  //     } else {
  //       // if (localStorage.getItem("received_inquiry")) {
  //       //   successnotify(
  //       //     "We have received your inquiry. Please be patient, and we will contact you as soon as possible!"
  //       //   );
  //       // } else {
  //       dispatch(changeGetAccessModalDisplay(true));
  //       // }
  //     }
  //   } else {
  //     setRange(val);
  //   }
  // };

  if (data.length === 0) return null;

  return (
    <Card style={{ minHeight: "600px" }}>
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">PTC TOU Rate Trend</h4>
        {dataSource && isLogin && (
          <MyTooltip>
            {(() => {
              const {
                calculation_formulas,
                data_source,
                peak_source,
                peak_desc,
              } = dataSource;

              const {
                mid_peak_desc,
                off_peak_desc,
                on_peak_desc,
                super_off_peak_desc,
              } = peak_desc || {};

              return (
                <div className="d-flex flex-column gap-4">
                  {calculation_formulas && (
                    <div>
                      <h6 style={{ color: "#fff" }}>
                        Data Calculation Formulas
                      </h6>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: calculation_formulas,
                        }}
                      />
                    </div>
                  )}
                  {data_source.length > 0 && (
                    <div>
                      <h6 style={{ color: "#fff" }}>Data Source</h6>
                      {data_source.map((link: any, idx: any) => (
                        <p key={idx}>
                          <a href={link} target="_blank" rel="noreferrer">
                            {link}
                          </a>
                        </p>
                      ))}
                    </div>
                  )}
                  {peak_source.length > 0 && (
                    <div>
                      <h6 style={{ color: "#fff" }}>Peak Time Data Source</h6>
                      {peak_source.map((link: any, idx: any) => (
                        <p key={idx}>
                          <a href={link} target="_blank" rel="noreferrer">
                            {link}
                          </a>
                        </p>
                      ))}
                    </div>
                  )}
                  <div>
                    <h6 style={{ color: "#fff" }}>Time Period</h6>
                    {on_peak_desc && <p>On Peak: {on_peak_desc}</p>}
                    {mid_peak_desc && <p>Mid Peak: {mid_peak_desc}</p>}
                    {off_peak_desc && <p>Off Peak: {off_peak_desc}</p>}
                    {super_off_peak_desc && (
                      <p>Super Off Peak: {super_off_peak_desc}</p>
                    )}
                  </div>
                </div>
              );
            })()}
          </MyTooltip>
        )}
      </CardHeader>
      <CardBody>
        {/* <div className="d-flex justify-content-between flex-wrap gap-2 mb-5">
            <div className="d-flex align-items-center flex-wrap gap-2">
              <span>Range:</span>
              {["3", "6", "12"].map((val: string, idx: number) => (
                <Button
                  onClick={() => checkAuthAndSetValue(val)}
                  color={range === val ? "secondary" : "light"}
                  key={idx}
                >
                  {val} Months
                </Button>
              ))}
            </div>
          </div> */}
        {isLoading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <MyBarChart
              type="PeakTimeChart"
              title={`${range}-Month Rate Trends`}
              // footer="Month/Year"
              data={data}
            />
          </React.Fragment>
        )}
        {!isLoading && <PeakTimeTable data={data} />}
      </CardBody>
    </Card>
  );
};

const PeakTimeTable = ({ data }: { data: any }) => {
  const formatTableData = (data: any) => {
    return data.map((item: any) => {
      const formatted: any = { id: item.id };
      item.data.forEach((entry: any) => {
        formatted[entry.x] = entry.y.toString();
      });
      return formatted;
    });
  };

  const calculateAverage = (obj: any) => {
    const values: any = Object.values(obj).filter(
      (value: any) => !isNaN(parseFloat(value))
    );
    const total = values.reduce(
      (sum: any, value: any) => sum + parseFloat(value),
      0
    );
    return total / values.length;
  };

  const tableData = formatTableData(data) || [];
  const sortedTableData = tableData.sort(
    (a: any, b: any) => calculateAverage(a) - calculateAverage(b)
  );

  const frozenColumns = [
    {
      name: <span className="font-weight-bold fs-14">Rate Type</span>,
      selector: () => "PTC Rate",
    },
    {
      name: <span className="font-weight-bold fs-14">Period</span>,
      selector: (row: any) => row.id,
    },
  ];

  const scrollableColumns = Object.keys(sortedTableData[0] || {})
    .filter((key) => key !== "id")
    .map((dateKey) => ({
      name: dateKey,
      selector: (row: any) => `${row[dateKey]}¢`,
    }));

  if (sortedTableData.length === 0) return null;

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F2F2F2",
      },
    },
  };
  return (
    <div className="combined-table">
      <div className="fronzen-table">
        <DataTable
          columns={frozenColumns}
          data={sortedTableData}
          paginationPerPage={10}
          customStyles={{
            cells: { style: { backgroundColor: "#fafafa" } },
            ...customStyles,
          }}
          pagination={false}
        />
      </div>
      <div className="scrollable-table">
        <DataTable
          columns={scrollableColumns}
          data={sortedTableData}
          paginationPerPage={10}
          customStyles={customStyles}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default USN_PeaktimeRateTrends;
