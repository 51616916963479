import { useEffect, useState } from "react";
import "../styles/myplan.css";
import { fetchAverageRateList } from "../api/market";
import moment from "moment";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import Loader from "./Common/Loader";
import { NoResult } from "./Common/NoResult";
import { useSelector } from "react-redux";
import { selectUtility } from "../store/utility/selector";
import UsageBtnGroup from "./UsageBtnGroup";

const LoginMarketAverageRate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [filterValue, setFilterValue] = useState({
    utility_id: "",
    price: 500,
  });
  const currentUtility = useSelector(selectUtility);

  useEffect(() => {
    if (!currentUtility) return;
    getData();
  }, [filterValue, currentUtility]);

  const getData = () => {
    setIsLoading(true);
    setData(null);
    fetchAverageRateList(filterValue).then((res) => {
      if (res.status === 1) {
        setData(res.response);
      }
      setIsLoading(false);
    });
  };

  const AverageRate = () => {
    let {
      yesterday_average_rate,
      last_week_average_rate,
      last_month_average_rate,
      last_year_average_rate,
    } = data || {};
    return (
      <Row>
        <Col xs={6} sm={3}>
          <div className="border p-2 rounded border-dashed">
            <p className="text-muted text-truncate mb-2">{`${moment()
              .subtract(1, "days")
              .format("MM/DD/YYYY")}`}</p>
            <h5 className="mb-0">{yesterday_average_rate || 0}¢</h5>
          </div>
        </Col>

        <Col xs={6} sm={3}>
          <div className="border p-2 rounded border-dashed">
            <p className="text-muted text-truncate mb-2">7 Days Ago</p>
            <h5 className="mb-0">{last_week_average_rate || 0}¢</h5>
          </div>
        </Col>

        <Col xs={6} sm={3}>
          <div className="border p-2 rounded border-dashed">
            <p className="text-muted text-truncate mb-2">30 Days Ago</p>
            <h5 className="mb-0">{last_month_average_rate || 0}¢</h5>
          </div>
        </Col>

        <Col xs={6} sm={3}>
          <div className="border p-2 rounded border-dashed">
            <p className="text-muted text-truncate mb-2">1 Year Ago</p>
            <h5 className="mb-0">{last_year_average_rate || 0}¢</h5>
          </div>
        </Col>
      </Row>
    );
  };

  const changeUsage = (val: string) => {
    setFilterValue({
      ...filterValue,
      price: +val,
    });
  };

  return (
    <Card>
      <CardHeader className="align-items-center justify-content-between d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Average Rate</h4>
      </CardHeader>
      <CardBody>
        <div className="mb-3">
          <UsageBtnGroup
            onChangeValue={(val: string) => changeUsage(val)}
            isLoading={isLoading}
          />
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>{data ? <AverageRate /> : <NoResult />}</div>
        )}
      </CardBody>
    </Card>
  );
};
export default LoginMarketAverageRate;
