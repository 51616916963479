import CountUp from "react-countup";
import Loader from "./Common/Loader";
import { getLogoSrc } from "../helpers/func";
import { useSelector } from "react-redux";
import { selectMyProvider } from "../store/myProvider/selector";
import { Col, Container, Row } from "reactstrap";

export const MySummary = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const myProvider = useSelector(selectMyProvider);

  if (!myProvider) return null;

  return (
    <Container>
      <div className="card crm-widget">
        <div className="card-body p-0">
          <Row className="row-cols-md-3 row-cols-1 g-0">
            <Col>
              <div className="pt-3 pb-2 px-4">
                <h5 className="text-muted text-uppercase fs-13 mb-0">Provider</h5>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    {myProvider ? (
                      <img
                        src={getLogoSrc(
                          myProvider.provider_id,
                          currentLocation?.state_id
                        )}
                        alt=""
                        height="60"
                      />
                    ) : (
                      <Loader />
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="pt-3 pb-2 px-4">
                <h5 className="text-muted text-uppercase fs-13 mb-3">Total Plans</h5>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    {myProvider ? (
                      <h2 className="mb-0 fs-24">
                        <span className="counter-value">
                          <CountUp
                            start={0}
                            separator=","
                            end={myProvider.plan_nums}
                            duration={1}
                          />
                        </span>
                      </h2>
                    ) : (
                      <Loader />
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="pt-3 pb-2 px-4">
                <h5 className="text-muted text-uppercase fs-13 mb-3">Update Time</h5>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    {myProvider ? (
                      <h2 className="mb-0 fs-24">
                        <span className="counter-value">{myProvider.time}</span>
                      </h2>
                    ) : (
                      <Loader />
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};
