import { ResponsiveLine } from "@nivo/line";
import {
  ChartBlock,
  ChartTitle,
  ChartWrapper,
  CustomTooltip,
  CustomTooltipX,
  CustomTooltipY,
  CustomTooltipYLabel,
  CustomTooltipYVal,
} from "../Styles/Chart.styled";
import { BaseChartProps } from "../../common/chart-config";
import { DataSeriesTypes } from "../../types";
import { NoResult } from "../Common/NoResult";
import { getMaxChartValue, getMinChartValue } from "../../common/functions";
import { mergeObjects } from "./helper";
import moment from "moment";
import Loader from "../Common/Loader";

interface ChartProps {
  title?: string;
  data: DataSeriesTypes[];
  isLoading?: boolean;
}

export const MultiRankChart: React.FC<ChartProps> = ({
  title,
  data,
  isLoading = false,
}) => {
  let isMobile = document.body.clientWidth <= 786;

  const dataSize = data?.[0]?.data?.length || 0;

  const chatConfig = {
    axisBottom: {
      tickValues: isMobile
        ? "every 5 days"
        : `every ${dataSize > 12 ? 3 : 1} days`,
    },
    axisLeft: {
      legend: "Rank",
    },
    margin: {
      bottom: 100,
    },
    sliceTooltip: ({ slice }: { slice: any }) => {
      if (slice) {
        const { points } = slice || {};
        if (points) {
          const xVal = points[0]?.data?.xFormatted || "";
          const xValFormatted = xVal
            ? moment(xVal, "MM/DD/YYYY").format("MMM DD")
            : "";
          return (
            <CustomTooltip>
              <CustomTooltipX>{xValFormatted}</CustomTooltipX>
              {points.map((val: any, idx: number) => {
                const { detail } = val?.data || {};
                return (
                  <div key={idx}>
                    <CustomTooltipY>
                      <CustomTooltipYLabel color={val.serieColor}>
                        <i />
                        <span>{val.serieId}</span>
                      </CustomTooltipYLabel>
                      <CustomTooltipYVal>
                        {`Rank ${val.data.yFormatted}`}
                      </CustomTooltipYVal>
                    </CustomTooltipY>
                    {detail?.base_rate && (
                      <CustomTooltipY>
                        <CustomTooltipYLabel color={val.serieColor}>
                          REP/Rate
                        </CustomTooltipYLabel>
                        <CustomTooltipYVal>
                          {`${detail.rank_rep}/${detail.base_rate}¢`}
                        </CustomTooltipYVal>
                      </CustomTooltipY>
                    )}
                    {detail?.Rank1_price && (
                      <CustomTooltipY>
                        <CustomTooltipYLabel color={val.serieColor}>
                          REP/Rate (Rank 1)
                        </CustomTooltipYLabel>
                        <CustomTooltipYVal>
                          {`${detail.Rank1_rep}/${detail.Rank1_price}¢`}
                        </CustomTooltipYVal>
                      </CustomTooltipY>
                    )}
                  </div>
                );
              })}
            </CustomTooltip>
          );
        }
      }
      return null;
    },
  };

  const maxVal = getMaxChartValue(data || []);
  const minVal = getMinChartValue(data || []);
  const newConfig = mergeObjects(BaseChartProps, chatConfig);

  return (
    <ChartBlock style={{ minHeight: "320px" }}>
      {title && <ChartTitle>{title}</ChartTitle>}
      <ChartWrapper xs>
        {isLoading ? (
          <Loader />
        ) : data?.length === 0 ? (
          <NoResult />
        ) : (
          <ResponsiveLine
            {...newConfig}
            data={data}
            yScale={{
              type: "linear",
              min: minVal,
              max: maxVal,
            }}
          />
        )}
      </ChartWrapper>
    </ChartBlock>
  );
};
