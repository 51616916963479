import { call, put, takeLatest, all, select } from "redux-saga/effects";
import { FETCH_MY_PROVIDER_REQUEST, CHANGE_MY_PROVIDER } from "./actionType";
import {
  fetchMyProviderSuccess,
  fetchMyProviderFailure,
  setMyProvider,
} from "./action";
import { selectLocation } from "../location/selector";
import { fetchMyProviderInfo } from "../../api/market";
import { fetchUSNMyProviderInfo } from "../../api/usnMarket";
import { Auth } from "aws-amplify";
import { selectUtility } from "../utility/selector";

function* fetchMyProviderSaga() {
  try {
    const currentLocation = yield select(selectLocation);
    const currentUtility = yield select(selectUtility);
    const user = yield Auth.currentUserInfo();

    if (!currentLocation || !currentUtility || !user) return;

    yield put(setMyProvider(null));

    const isTX = currentLocation?.state_id === "TX";
    const res = isTX
      ? yield call(fetchMyProviderInfo, currentUtility?.utility_id)
      : yield call(
          fetchUSNMyProviderInfo,
          currentLocation?.state_id,
          "1",
          currentUtility?.utility_id
        );

    yield put(fetchMyProviderSuccess());
    yield put(setMyProvider(res?.response || null));
  } catch (error) {
    console.log(error);
    yield put(fetchMyProviderFailure(error.message));
  }
}

function* changeMyProviderSaga(action) {
  const newUtility = action.payload;
  yield put(setMyProvider(newUtility));
}

function* watchFetchMyProvider() {
  yield takeLatest(FETCH_MY_PROVIDER_REQUEST, fetchMyProviderSaga);
}

function* watchChangeMyProvider() {
  yield takeLatest(CHANGE_MY_PROVIDER, changeMyProviderSaga);
}

export default function* myProviderSaga() {
  yield all([watchFetchMyProvider(), watchChangeMyProvider()]);
}
