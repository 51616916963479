import { ResponsiveLine } from "@nivo/line";
import {
  ChartBlock,
  ChartTitle,
  ChartWrapper,
  CustomTooltip,
  CustomTooltipX,
  CustomTooltipY,
  CustomTooltipYLabel,
  CustomTooltipYVal,
} from "../Styles/Chart.styled";
import { BaseChartProps } from "../../common/chart-config";
import { DataSeriesTypes } from "../../types";
import { NoResult } from "../Common/NoResult";
import { getMaxChartValue, getMinChartValue } from "../../common/functions";
import { mergeObjects } from "./helper";
import moment from "moment";
import Loader from "../Common/Loader";

interface ChartProps {
  title?: string;
  data: DataSeriesTypes[];
  isLoading?: boolean;
}

export const PriceEstimateChart: React.FC<ChartProps> = ({
  title,
  data,
  isLoading = false,
}) => {
  const chatConfig = {
    yFormat: (val: number) => val + "¢",
    axisBottom: {
      format: (val: any) => val + "kWh",
      tickValues: 7,
    },
    margin: {
      bottom: 100,
    },
    xScale: {
      type: "linear",
    },
    sliceTooltip: ({ slice }: { slice: any }) => {
      if (slice) {
        const { points } = slice || {};
        if (points) {
          const xVal = points[0]?.data?.x || "";

          return (
            <CustomTooltip>
              <CustomTooltipX>{`${xVal} kWh`}</CustomTooltipX>
              {points.map((val: any, idx: number) => (
                <CustomTooltipY key={idx}>
                  <CustomTooltipYLabel color={val.serieColor}>
                    <i />
                    <span>{val.serieId}</span>
                  </CustomTooltipYLabel>
                  <CustomTooltipYVal>{val.data.yFormatted}</CustomTooltipYVal>
                </CustomTooltipY>
              ))}
            </CustomTooltip>
          );
        }
      }
      return null;
    },
  };

  const maxVal = getMaxChartValue(data || []);
  const minVal = getMinChartValue(data || []);
  const newConfig = mergeObjects(BaseChartProps, chatConfig);

  return (
    <ChartBlock>
      {title && <ChartTitle>{title}</ChartTitle>}
      <ChartWrapper>
        {isLoading ? (
          <Loader />
        ) : data?.length === 0 ? (
          <NoResult />
        ) : (
          <ResponsiveLine
            {...newConfig}
            data={data}
            yScale={{
              type: "linear",
              min: minVal,
              max: maxVal,
            }}
          />
        )}
      </ChartWrapper>
    </ChartBlock>
  );
};
