import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useAuth } from "../Hooks/UserHooks";

import {
  changePasswordDisplay,
  changeProfileModalDisplay,
  changeREPDisplay,
} from "../../store/actions";

//import images
import avatar from "../../assets/images/users/person_account_icon.svg";
import { Auth } from "aws-amplify";

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const { userProfile } = useAuth();

  useEffect(() => {
    if (userProfile && userProfile.attributes) {
      const { email } = userProfile.attributes;
      setUserName(email);
    }

    booleanAdmin();
  }, [userProfile]);

  //Dropdown Toggle

  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  // isAdmin
  const booleanAdmin = () => {
    Auth.currentAuthenticatedUser().then((user) => {
      const { payload } = user.signInUserSession.accessToken;
      if (!payload["cognito:groups"]) return;
      const groups = payload["cognito:groups"] || "";
      if (groups.indexOf("SuperAdmin") >= 0) {
        setIsAdmin(true);
      }
    });
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src={avatar}
              alt="Header Avatar"
            />
            <span className="text-start ms-xl-1">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {userName}
              </span>
              {/* <span className="d-none d-xl-block ms-1 fs-13 text-muted user-name-sub-text">Founder</span> */}
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header text-uppercase">
            Welcome back{userName ? `, ${userName.split("@")?.[0]}` : ""}
          </h6>
          <DropdownItem
            onClick={() => {
              dispatch(changeProfileModalDisplay(true));
            }}
          >
            <i className="ri-mail-fill text-muted fs-17 align-middle me-1"></i>
            <span className="align-middle">Email Report</span>
          </DropdownItem>

          <DropdownItem
            onClick={() => {
              // console.log(changePasswordDisplay)
              dispatch(changePasswordDisplay(true));
            }}
          >
            <i className="mdi mdi-account-circle text-muted fs-17 align-middle me-1"></i>
            <span className="align-middle">Profile</span>
          </DropdownItem>
          {isAdmin && (
            <DropdownItem
              onClick={() => {
                // console.log(changePasswordDisplay)
                dispatch(changeREPDisplay(true));
              }}
            >
              <i className="ri-building-line  text-muted fs-17 align-middle me-1"></i>
              <span className="align-middle">Change REP</span>
            </DropdownItem>
          )}
          <div className="dropdown-divider"></div>
          <DropdownItem href="/logout">
            <i className="mdi mdi-logout text-muted fs-17 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
