import { Helmet } from "react-helmet";
import UnpublishedPlan from "./UnpublishedPlan";
import { Container } from "reactstrap";

export default function Mismatched() {
  return (
    <div className="page-content">
      <Helmet>
        <title>PowerRateIndex</title>
      </Helmet>
      <Container>
        <UnpublishedPlan />
      </Container>
    </div>
  );
}
