import {
  CHANGE_MY_PROVIDER,
  SET_MY_PROVIDER,
  FETCH_MY_PROVIDER_REQUEST,
  FETCH_MY_PROVIDER_SUCCESS,
  FETCH_MY_PROVIDER_FAILURE,
} from "./actionType";

export const changeMyProvider = (provider) => ({
  type: CHANGE_MY_PROVIDER,
  payload: provider,
});

export const setMyProvider = (provider) => ({
  type: SET_MY_PROVIDER,
  payload: provider,
});

export const fetchMyProviderRequest = () => ({
  type: FETCH_MY_PROVIDER_REQUEST,
});

export const fetchMyProviderSuccess = () => ({
  type: FETCH_MY_PROVIDER_SUCCESS,
});

export const fetchMyProviderFailure = (error) => ({
  type: FETCH_MY_PROVIDER_FAILURE,
  payload: error,
});
