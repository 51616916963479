import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import Loader from "../Common/Loader";
import { StateList } from "../Common/StateList";
import TxDataTable from "./TxDataTable";
import UsnDataTable from "./UsnDataTable";
import { exportTxDashboard, exportUsnDashboard } from "../../api/dashboard";

const ExpandIcon = () => <i className="ri-arrow-down-s-line" />;

const Terms = [
  "1",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "28",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "39",
  "48",
  "54",
  "60",
];

const StateSection = ({
  data,
  stateId,
  loading,
  handleRequestData,
}: {
  data: any;
  stateId: string;
  loading: boolean;
  handleRequestData: Function;
}) => {
  // console.log(stateId, data);

  const [term, setTerm] = useState("");
  const [termList, setTermList] = useState(Terms);
  const [openTerms, setOpenTerms] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [hideNoPlanUtility, setHideNoPlanUtility] = useState(false);

  const stateName =
    StateList.find((item) => item.state_id === stateId)?.state_name || "";

  const download = async () => {
    if (isDownloading) return;

    setIsDownloading(true);
    const res =
      stateId === "TX"
        ? await exportTxDashboard(term)
        : await exportUsnDashboard(stateId, term);

    const fileName = res.headers
      .get("content-disposition")
      ?.split("filename=")[1];

    const data = await res.blob();

    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute("download", fileName || `${stateId}_dashboard.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography>{stateName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Row>
          <Col xs="5" md="4">
            <Dropdown
              isOpen={openTerms}
              toggle={() => setOpenTerms(!openTerms)}
            >
              <DropdownToggle tag="button" className="btn btn-light">
                {term ? `${term} Month` : "All Term"}{" "}
                <i className="mdi mdi-chevron-down"></i>
              </DropdownToggle>
              <DropdownMenu style={{ overflow: "scroll", height: "20rem" }}>
                <DropdownItem
                  key={"allterm"}
                  onClick={() => {
                    setTerm("");
                    handleRequestData(stateId, "");
                  }}
                >
                  All Term
                </DropdownItem>
                {termList.map((item: any, index: any) => (
                  <DropdownItem
                    key={index}
                    onClick={() => {
                      setTerm(item);
                      handleRequestData(stateId, item);
                    }}
                  >
                    {item} Month
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col xs="7" md="8">
            <Row>
              <Col xs="12" md="8" className="d-flex align-items-center justify-content-end">
                <div className="form-check form-switch form-switch-md d-flex align-items-center form-switch-secondary">
                  <Label className="text-muted d-flex align-items-center justify-content-between mb-0">
                    <Input
                      className="code-switch"
                      type="checkbox"
                      value=""
                      checked={hideNoPlanUtility}
                      onChange={(e) => {
                        setHideNoPlanUtility(e.target.checked);
                      }}
                    />
                    Hide the utility without any plans
                  </Label>
                </div>
              </Col>
              <Col xs="12" md="4" className="d-flex justify-content-end">
                {!isDownloading ? (
                  <Button
                    color="secondary"
                    onClick={download}
                    className="btn-label"
                  >
                    <i className="bx bx-download label-icon align-middle fs-17 me-2"></i>
                    <span>Download</span>
                  </Button>
                ) : (
                  <Button color="secondary" className="btn-load" outline>
                    <span className="d-flex align-items-center">
                      <Spinner size="sm" className="flex-shrink-0">
                        {" "}
                        Loading...{" "}
                      </Spinner>
                      <span className="flex-grow-1 ms-2">Loading...</span>
                    </span>
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="state-section-body mt-3">
          {loading ? (
            <Loader />
          ) : stateId === "TX" ? (
            <TxDataTable data={data} isHide={hideNoPlanUtility} />
          ) : (
            <UsnDataTable data={data} isHide={hideNoPlanUtility} />
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default StateSection;
