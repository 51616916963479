import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import Loader from "./Common/Loader";
import { fetchUsagePlans, fetchUsages } from "../api/customize";
import { errornotify } from "./Message";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { jumpTo } from "./UrlModal";
import { useSelector } from "react-redux";
import { selectUtility } from "../store/utility/selector";
import { BarDatum, ResponsiveBar } from "@nivo/bar";
import {
  ChartWrapper,
  CustomTooltip,
  CustomTooltipYItem,
  CustomTooltipYLabel,
  CustomTooltipYVal,
} from "./Styles/Chart.styled";
import { NoResult } from "./Common/NoResult";

interface usagePlanObj {
  plan_id: string;
  pd_id: string;
  plan_name: string;
  provider_id: string;
  utility_id: string;
  provider_name: string;
  utility_name: string;
  term: string;
  your_rate: string;
  cancel_fee: string;
  created_ts: string;
  offline_ts: string;
}
interface usagesArr {
  big: Array<number>;
  apartment: Array<number>;
  medium: Array<number>;
  small: Array<number>;
}

const today = moment().format("YYYY-MM-DD");

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function Customize() {
  const [isLoading, setIsLoading] = useState(false);
  const [dataPlanList, setDataPlanList] = useState<Array<usagePlanObj>>([]);
  const [values, setValues] = useState<any>([]);
  const [btnName, setBtnName] = useState("big");
  const [usagesNums, setUsagesNums] = useState<usagesArr>({
    big: [],
    apartment: [],
    medium: [],
    small: [],
  });
  const [value, setValue] = React.useState<any>(today);
  const currentUtility = useSelector(selectUtility);
  const [filterValue, setFilterValue] = useState({
    created_ts: today,
    usages: [],
  });

  let chartData = [];
  for (let i in values) {
    chartData.push({
      date: months[parseInt(i)],
      usage: parseInt(values[i]),
    });
  }

  const getPlanList = async (val: any) => {
    setIsLoading(true);
    const res = await fetchUsagePlans({
      utility_id: currentUtility.utility_id,
      ...val,
    });
    if (res.status !== 1) {
      return setIsLoading(false);
    }
    setDataPlanList(res.response);
    setIsLoading(false);
  };

  const getUsagesNums = async () => {
    const res = await fetchUsages();
    if (res.status !== 1) {
      // errornotify(res.message);
      return;
    }
    setUsagesNums(res.response);
    setValues(res.response.big);
    setFilterValue({
      ...filterValue,
      usages: res.response.big,
    });
  };
  useEffect(() => {
    getUsagesNums();
  }, []);

  useEffect(() => {
    if (filterValue.usages.length !== 0 && currentUtility) {
      getPlanList(filterValue);
    }
  }, [filterValue, currentUtility]);

  const changeValue = (evt: any) => {
    const val = evt.target.value;
    const key = +evt.target.name;
    let newValues = Object.assign({}, values);
    newValues[key] = val ? parseInt(val) : 0;
    setValues(newValues);
  };

  const changeDate = (val: any) => {
    setFilterValue({
      ...filterValue,
      created_ts: moment(val).format("YYYY-MM-DD"),
    });
  };

  const TopPlan = () => {
    const columns = [
      {
        name: <span className="font-weight-bold fs-14">REP Name</span>,
        selector: (row: any) => row.provider_name,
      },
      {
        name: <span className="font-weight-bold fs-14">Plan Name</span>,
        selector: (row: any) => row.plan_name,
      },
      {
        name: <span className="font-weight-bold fs-14">Term</span>,
        selector: (row: any) => `${row.term}`,
        width: "70px",
      },
      {
        name: <span className="font-weight-bold fs-14">Your Rate</span>,
        selector: (row: any) => `${row.your_rate}¢`,
        width: "130px",
      },
      {
        name: <span className="font-weight-bold fs-14">Cancellation Fee</span>,
        selector: (row: any) => row.cancel_fee,
      },
      {
        name: <span className="font-weight-bold fs-14">Created Date</span>,
        selector: (row: any) => row.created_ts,
      },
      {
        name: <span className="font-weight-bold fs-14"></span>,
        selector: (row: any) => {
          return (
            <div className="d-flex flex-wrap gap-2">
              <Button
                onClick={() => {
                  jumpTo(row.plan_id, row.pd_id);
                }}
                outline
                target="_blank"
                rel="noreferrer"
                className="btn btn-sm"
              >
                EFL
              </Button>
            </div>
          );
        },
      },
    ];
    const customStyles = {
      headCells: {
        style: {
          backgroundColor: "#F2F2F2",
        },
      },
    };

    return (
      <DataTable
        columns={columns}
        keyField="plan_id"
        data={dataPlanList}
        paginationPerPage={10}
        customStyles={customStyles}
        pagination
      />
    );
  };

  return (
    <div className="page-content">
      <Container>
        <Row>
          <Col sm="12" lg="4">
            <Card>
              <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">
                  Create Usage Profile
                </h4>
              </CardHeader>
              <CardBody>
                <ChartWrapper style={{ height: "160px" }}>
                  <ResponsiveBar
                    data={chartData as BarDatum[]}
                    keys={["usage"]}
                    indexBy="date"
                    valueScale={{ type: "linear" }}
                    indexScale={{ type: "band", round: true }}
                    margin={{ top: 10, right: 10, bottom: 30, left: 10 }}
                    padding={0.7}
                    axisLeft={null}
                    enableLabel={false}
                    borderRadius={6}
                    colors="rgb(209, 217, 215)"
                    tooltip={(bar: any) => {
                      return (
                        <CustomTooltip>
                          <CustomTooltipYItem>
                            <CustomTooltipYLabel color={bar.color}>
                              <i />
                              <span>{bar.indexValue}</span>
                            </CustomTooltipYLabel>
                            <CustomTooltipYVal>{`${bar.value}kWh`}</CustomTooltipYVal>
                          </CustomTooltipYItem>
                        </CustomTooltip>
                      );
                    }}
                  />
                </ChartWrapper>
                <div className="d-flex flex-wrap gap-2 mt-3 mb-3">
                  <ButtonGroup>
                    <Button
                      onClick={() => {
                        setValues(usagesNums?.big);
                        setBtnName("big");
                      }}
                      size="sm"
                      active={btnName === "big"}
                      color="secondary"
                      outline
                    >
                      Big House
                    </Button>
                    <Button
                      onClick={() => {
                        setValues(usagesNums?.apartment);
                        setBtnName("apartment");
                      }}
                      size="sm"
                      active={btnName === "apartment"}
                      color="secondary"
                      outline
                    >
                      Apartment
                    </Button>
                    <Button
                      onClick={() => {
                        setValues(usagesNums?.medium);
                        setBtnName("medium");
                      }}
                      size="sm"
                      active={btnName === "medium"}
                      color="secondary"
                      outline
                    >
                      Medium
                    </Button>
                    <Button
                      onClick={() => {
                        setValues(usagesNums?.small);
                        setBtnName("small");
                      }}
                      size="sm"
                      active={btnName === "small"}
                      color="secondary"
                      outline
                    >
                      Small House
                    </Button>
                  </ButtonGroup>
                </div>
                {values.length === 0 ? (
                  <Loader />
                ) : (
                  <form>
                    <Row>
                      <Col span={4}>
                        <Label>Jan:</Label>
                        <Input
                          name="1"
                          value={values[0]}
                          onChange={(evt) => changeValue(evt)}
                        />
                      </Col>
                      <Col span={4}>
                        <Label>Feb:</Label>
                        <Input
                          name="2"
                          value={values[1]}
                          onChange={(evt) => changeValue(evt)}
                        />
                      </Col>
                      <Col span={4}>
                        <Label>Mar:</Label>
                        <Input
                          name="3"
                          value={values[2]}
                          onChange={(evt) => changeValue(evt)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col span={4}>
                        <Label>Apr:</Label>
                        <Input
                          name="4"
                          value={values[3]}
                          onChange={(evt) => changeValue(evt)}
                        />
                      </Col>
                      <Col span={4}>
                        <Label>May:</Label>
                        <Input
                          name="5"
                          value={values[4]}
                          onChange={(evt) => changeValue(evt)}
                        />
                      </Col>
                      <Col span={4}>
                        <Label>Jun:</Label>
                        <Input
                          name="6"
                          value={values[5]}
                          onChange={(evt) => changeValue(evt)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col span={4}>
                        <Label>Jul:</Label>
                        <Input
                          name="7"
                          value={values[6]}
                          onChange={(evt) => changeValue(evt)}
                        />
                      </Col>
                      <Col span={4}>
                        <Label>Aug:</Label>
                        <Input
                          name="8"
                          value={values[7]}
                          onChange={(evt) => changeValue(evt)}
                        />
                      </Col>
                      <Col span={4}>
                        <Label>Sep:</Label>
                        <Input
                          name="9"
                          value={values[8]}
                          onChange={(evt) => changeValue(evt)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col span={4}>
                        <Label>Oct:</Label>
                        <Input
                          name="10"
                          value={values[9]}
                          onChange={(evt) => changeValue(evt)}
                        />
                      </Col>
                      <Col span={4}>
                        <Label>Nov:</Label>
                        <Input
                          name="11"
                          value={values[10]}
                          onChange={(evt) => changeValue(evt)}
                        />
                      </Col>
                      <Col span={4}>
                        <Label>Dec:</Label>
                        <Input
                          name="12"
                          value={values[11]}
                          onChange={(evt) => changeValue(evt)}
                        />
                      </Col>
                    </Row>
                    <div className="d-grid mt-4">
                      <Button
                        color="secondary"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          setFilterValue({
                            ...filterValue,
                            usages: values,
                          });
                        }}
                      >
                        Confirm
                      </Button>
                    </div>
                  </form>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" lg="8">
            <Card>
              <CardBody>
                <div className="mb-4 d-flex justify-content-end">
                  <Flatpickr
                    className="rank_datepicker"
                    value={value}
                    options={{
                      dateFormat: "Y-m-d",
                      defaultDate: [today],
                      minDate: moment().startOf("year").format("YYYY-MM-DD"),
                      maxDate: today,
                      allowInput: true,
                    }}
                    onChange={([newValue]: any) => {
                      setValue(moment(newValue).format("YYYY-MM-DD"));
                      changeDate(newValue);
                    }}
                  />
                </div>
                {isLoading ? (
                  <Loader />
                ) : dataPlanList?.length > 0 ? (
                  <TopPlan />
                ) : (
                  <NoResult />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
