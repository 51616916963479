import { ResponsiveLine } from "@nivo/line";
import {
  ChartBlock,
  ChartTitle,
  ChartWrapper,
  CustomTooltip,
  CustomTooltipY,
  CustomTooltipYLabel,
  CustomTooltipYVal,
} from "../Styles/Chart.styled";
import { BaseChartProps } from "../../common/chart-config";
import { DataSeriesTypes } from "../../types";
import { NoResult } from "../Common/NoResult";
import {
  getPRIMaxChartValue,
  getPRIMinChartValue,
} from "../../common/functions";
import { mergeObjects } from "./helper";
import moment from "moment";
import Loader from "../Common/Loader";

interface ChartProps {
  title?: string;
  data: DataSeriesTypes[];
  isLoading?: boolean;
}

export const TrendChart: React.FC<ChartProps> = ({
  title,
  data,
  isLoading = false,
}) => {
  let isMobile = document.body.clientWidth <= 786;

  const chatConfig = {
    yFormat: (val: number) => (val * 100).toFixed(0) + "%",
    axisBottom: {
      tickValues: isMobile ? "every 6 months" : "every 2 months",
      format: "%b %Y",
    },
    axisLeft: {
      legend: "% Chg vs YA",
      tickValues: 2,
      format: (val: number) => (val * 100).toFixed(0) + "%",
    },
    margin: {
      bottom: 50,
    },
    legends: [],
    markers: [
      {
        axis: "y",
        value: "0",
        lineStyle: { stroke: "#999", strokeWidth: 1.5 },
      },
    ],
    sliceTooltip: ({ slice }: { slice: any }) => {
      if (slice) {
        const { points } = slice || {};
        if (points) {
          const xVal = points[0]?.data?.xFormatted || "";
          const y = points[0]?.data?.y || "";
          const xValFormatted = xVal
            ? moment(xVal, "MM/DD/YYYY").format("MMM YYYY")
            : "";
          let ascend: any = undefined;
          if (y) {
            ascend = Number(y) === 0 ? undefined : Number(y) > 0 ? true : false;
          }
          return (
            <CustomTooltip>
              {points.map((val: any, idx: number) => (
                <CustomTooltipY key={idx}>
                  <CustomTooltipYLabel color={val.serieColor}>
                    {xValFormatted}
                  </CustomTooltipYLabel>
                  <CustomTooltipYVal
                    color={
                      ascend === undefined
                        ? "inherit"
                        : ascend
                        ? "#00E09E"
                        : "#C93756"
                    }
                  >
                    {ascend === true && (
                      <i className={" align-middle ri-arrow-right-up-line"}></i>
                    )}
                    {ascend === false && (
                      <i
                        className={"align-middle ri-arrow-right-down-line"}
                      ></i>
                    )}
                    <span>{val.data.yFormatted}</span>
                  </CustomTooltipYVal>
                </CustomTooltipY>
              ))}
            </CustomTooltip>
          );
        }
      }
      return null;
    },
  };

  const maxVal = getPRIMaxChartValue(data || []);
  const minVal = getPRIMinChartValue(data || []);
  const newConfig = mergeObjects(BaseChartProps, chatConfig);

  return (
    <ChartBlock>
      {title && <ChartTitle>{title}</ChartTitle>}
      <ChartWrapper>
        {isLoading ? (
          <Loader />
        ) : data?.length === 0 ? (
          <NoResult />
        ) : (
          <ResponsiveLine
            {...newConfig}
            data={data}
            yScale={{
              type: "linear",
              min: minVal,
              max: maxVal,
            }}
          />
        )}
      </ChartWrapper>
    </ChartBlock>
  );
};
