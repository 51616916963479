export const mergeObjects = (target: any, source: any) => {
  const newObj = JSON.parse(JSON.stringify(target));
  for (let key in source) {
    if (
      source[key] &&
      typeof source[key] === "object" &&
      !Array.isArray(source[key])
    ) {
      newObj[key] = mergeObjects(newObj[key] || {}, source[key]);
    } else {
      newObj[key] = source[key];
    }
  }
  return newObj;
};
