import React, { useEffect, useState } from "react";
import "../styles/myplan.css";
import moment from "moment";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import Loader from "./Common/Loader";
import { NoResult } from "./Common/NoResult";
import { fetchUSNAverageRate } from "../api/usnMarket";
import { useSelector } from "react-redux";
import { selectUtility } from "../store/utility/selector";

const USN_AverageRateGlance = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const currentUtility = useSelector(selectUtility);

  useEffect(() => {
    const getData = () => {
      setIsLoading(true);
      setData(null);
      fetchUSNAverageRate(
        currentLocation?.state_id,
        currentUtility.utility_id
      ).then((res) => {
        if (res.status === 1) {
          setData(res.response);
        }
        setIsLoading(false);
      });
    };

    if (currentUtility) {
      getData();
    }
  }, [currentUtility]);

  const AverageRate = () => {
    let {
      yesterday_average_rate,
      last_week_average_rate,
      last_month_average_rate,
      last_year_average_rate,
    } = data || {};
    return (
      <Row>
        <Col xs={6} sm={3}>
          <div className="border p-2 rounded border-dashed">
            <p className="text-muted text-truncate mb-2">{`${moment()
              .subtract(1, "days")
              .format("MM/DD/YYYY")}`}</p>
            <h5 className="mb-0">{yesterday_average_rate || 0}¢</h5>
          </div>
        </Col>

        <Col xs={6} sm={3}>
          <div className="border p-2 rounded border-dashed">
            <p className="text-muted text-truncate mb-2">7 Days Ago</p>
            <h5 className="mb-0">{last_week_average_rate || 0}¢</h5>
          </div>
        </Col>

        <Col xs={6} sm={3}>
          <div className="border p-2 rounded border-dashed">
            <p className="text-muted text-truncate mb-2">30 Days Ago</p>
            <h5 className="mb-0">{last_month_average_rate || 0}¢</h5>
          </div>
        </Col>

        <Col xs={6} sm={3}>
          <div className="border p-2 rounded border-dashed">
            <p className="text-muted text-truncate mb-2">1 Year Ago</p>
            <h5 className="mb-0">{last_year_average_rate || 0}¢</h5>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <Card>
      <CardHeader className="align-items-center justify-content-between d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Market Average Rate</h4>
      </CardHeader>
      <CardBody>
        {isLoading ? (
          <Loader />
        ) : (
          <div>{data ? <AverageRate /> : <NoResult />}</div>
        )}
      </CardBody>
    </Card>
  );
};

export default USN_AverageRateGlance;
