import { ResponsiveLine } from "@nivo/line";
import {
  ChartBlock,
  ChartTitle,
  ChartWrapper,
  CustomTooltip,
  CustomTooltipX,
  CustomTooltipY,
  CustomTooltipYLabel,
  CustomTooltipYVal,
} from "../Styles/Chart.styled";
import { BaseChartProps } from "../../common/chart-config";
import { DataSeriesTypes } from "../../types";
import { NoResult } from "../Common/NoResult";
import { getMaxChartValue, getMinChartValue } from "../../common/functions";
import { mergeObjects } from "./helper";
import moment from "moment";
import Loader from "../Common/Loader";

interface ChartProps {
  title?: string;
  data: DataSeriesTypes[];
  isLoading?: boolean;
}

export const PriceComparisionChart: React.FC<ChartProps> = ({
  title,
  data,
  isLoading = false,
}) => {
  let isMobile = document.body.clientWidth <= 786;

  const chatConfig = {
    yFormat: (val: number) => val + "¢",
    axisBottom: {
      tickValues: isMobile ? "every 5 days" : "every 2 days",
    },
    axisLeft: {
      legend: "Rate/kWh (in cents)",
    },
    margin: {
      bottom: 100,
    },
    colors: { datum: "color" },
    enableArea: false,
    sliceTooltip: ({ slice }: { slice: any }) => {
      if (slice) {
        const { points } = slice || {};
        if (points) {
          const xVal = points[0]?.data?.xFormatted || "";
          const xValFormatted = xVal
            ? moment(xVal, "MM/DD/YYYY").format("MMM DD")
            : "";
          return (
            <CustomTooltip>
              <CustomTooltipX>{xValFormatted}</CustomTooltipX>
              {points.map((val: any, idx: number) => (
                <CustomTooltipY key={idx}>
                  <CustomTooltipYLabel color={val.serieColor}>
                    <i />
                    <span>{val.serieId}</span>
                  </CustomTooltipYLabel>
                  <CustomTooltipYVal>{val.data.yFormatted}</CustomTooltipYVal>
                </CustomTooltipY>
              ))}
            </CustomTooltip>
          );
        }
      }
      return null;
    },
  };

  const DashedSolidLine = ({ series, lineGenerator, xScale, yScale }: any) => {
    return series.map(({ id, data, color }: any) => (
      <path
        key={id}
        d={lineGenerator(
          data.map((d: any) => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y),
          }))
        )}
        fill="none"
        stroke={color}
        style={
          id.includes("Market")
            ? {
                // simulate line will dash stroke when index is even
                strokeDasharray: "3, 6",
                strokeWidth: 3,
              }
            : {
                // simulate line with solid stroke
                strokeWidth: 2.5,
              }
        }
      />
    ));
  };

  const maxVal = getMaxChartValue(data || []);
  const minVal = getMinChartValue(data || []);
  const newConfig = mergeObjects(BaseChartProps, chatConfig);

  return (
    <ChartBlock>
      {title && <ChartTitle>{title}</ChartTitle>}
      <ChartWrapper>
        {isLoading ? (
          <Loader />
        ) : data?.length === 0 ? (
          <NoResult />
        ) : (
          <ResponsiveLine
            {...newConfig}
            data={data}
            yScale={{
              type: "linear",
              min: minVal,
              max: maxVal,
            }}
            layers={[
              // includes all default layers
              "grid",
              "markers",
              "axes",
              "areas",
              "crosshair",
              // "line",
              "slices",
              "points",
              "mesh",
              "legends",
              DashedSolidLine, // add the custome layer here
            ]}
          />
        )}
      </ChartWrapper>
    </ChartBlock>
  );
};
