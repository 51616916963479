import { useState } from "react";
import { Button, ButtonGroup } from "reactstrap";

const UsageBtnGroup = ({
  defaultValue = "500",
  isLoading = false,
  onChangeValue,
}: {
  defaultValue?: string;
  isLoading?: boolean;
  onChangeValue: Function;
}) => {
  const [selected, setSelected] = useState<any>(defaultValue);

  const handleClick = (val: string) => {
    if (isLoading) return;

    setSelected(val);
    onChangeValue(val);
  };

  return (
    <ButtonGroup>
      {["500", "1000", "2000"].map((val) => (
        <Button
          color="secondary"
          outline
          key={val}
          onClick={() => handleClick(val)}
          active={selected === val}
        >
          {`${val} kWh`}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default UsageBtnGroup;
