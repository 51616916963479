import styled from "@emotion/styled";

export const CustomTooltip = styled("div")`
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 16px 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  color: #666;
`;

export const CustomTooltipX = styled("div")`
  font-size: 15px;
  padding-bottom: 12px;
  border-bottom: 1px solid #eee;
  margin-bottom: 12px;
  color: #999;
`;

export const CustomTooltipY = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;

export const CustomTooltipYItem = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CustomTooltipYLabel = styled("div")`
  display: flex;
  align-items: center;

  i {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: ${({ color }) => color};
    border-radius: 50%;
    margin-right: 8px;
  }
`;

export const CustomTooltipYVal = styled("div")`
  padding-left: 20px;
  font-weight: 500;
  color: #333;
  i {
    color: ${({ color }) => color};
  }
  span {
    color: ${({ color }) => color};
  }
`;

export const ChartTitle = styled("h2")`
  text-align: center;
  font-size: 1.6rem;
  margin: 0 auto;
`;

export const ChartFooter = styled("h2")`
  text-align: center;
  font-size: 1.2rem;
  margin: 0 auto;
`;

export const ChartWrapper = styled("div")<{
  xs?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ xs }) => (xs ? "320px" : "400px")};
`;

export const ChartBlock = styled("div")`
  margin: 20px 0;
  min-height: 400px;
  width: 100%;
`;

export const RaceChartWrapper = styled("div")`
  height: 520px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RaceChartSection = styled("div")`
  h2 {
    font-size: 20px;
  }

  span {
    font-size: 14px;
    font-weight: 500;
  }
`;
