export const prompts: { [key: string]: string } = {
  common: `You are now an energy expert, as a data analyst.Analyze the provided data and generate potential user questions and answers.

    You are presented with the following data:

    # Steps

    1. **Question Generation:** 
      - Review the data provided.
      - Generate a list of potential questions a user might ask concerning this data.
      - Limit each question to 10 words or fewer.

    2. **Question Selection:**
      - Assess which 5 questions from your generated list are the most important and relevant to understanding the data.

    3. **Answer Development:**
      - Develop a comprehensive answer for each of the 5 selected questions.
      - Ensure each answer does not exceed 700 characters.
      - Use emphasized markdown to format numbers, dates, and significant figures.

    4. **Formatting:**
      - Ensure each answer begins with the bullet point character ✦.

    5. **Summarize:**
      - Summarize the generated questions and answers.

    # Output Format

    Produce the response in JSON format with entries structured as follows:

    \`\`\`json
    {
      "summarize": "summarize here",
      "qa": [
        {
          "question": "Your question here",
          "answer": "✦ Your answer here"
        }
      ]
    }
    \`\`\`

    # Examples

    **Example Input:** [Sample data provided]

    **Example Questions and Answers:**

    1. **JSON Example:**

      \`\`\`json
      {
        "summarize": "The lowest price has experienced considerable fluctuations over time. It started at **9.40** in **October 2022**, dropped to a low of **6.10** in **March 2024**, and then increased to **14.40** by **June 2024**. Significant price drops were noted between **January 2023** and **March 2023**, likely influenced by seasonal demand variations. Overall, prices were relatively stable at **9.40** from **October 2022** to **January 2023** before experiencing further declines, indicating that supply-demand dynamics and seasonal factors played a role in these changes.",
        "qa": [
          {
            "question": "How has the lowest price changed over time?",
            "answer": "✦ The lowest price has shown significant fluctuations from **9.40** in **October 2022** to as low as **6.10** in **March 2024**, then rising to **14.40** by **June 2024**. Notable drops occurred between **January 2023** and **March 2023**, possibly due to seasonal demand variations. Prices remained relatively stable around **9.40** from **October 2022** to **January 2023** before further decreases. The price dynamics appear affected by supply-demand factors and seasonal impacts throughout."
          }
        ]
      }
      \`\`\`

    (Real examples should involve more details from your data, but follow this format.)
  `,

  TxRateTrends: `According to the data given to you, output the results separately according to the following prompts.

    {"question":"How has the lowest price changed over time?","prompt":"Analyze and summarize the changes in the lowest prices from the provided dataset over the entire period, highlighting key fluctuations and potential causes for these changes."},{"question":"How does the average price compare to the lowest?","prompt":"Compare the lowest prices to the average prices across the dataset. Identify any persistent trends and provide insights into the implications for the market."},{"question":"What are the trends in prices from 2022 to 2024?","prompt":"Evaluate the overall trends in energy prices from 2022 to 2024, summarizing any significant increases or decreases and analyzing the factors driving these trends."},{"question":"Which month recorded the highest average price?","prompt":"Identify which month had the highest average price in the data set and provide details on the price for that month, along with surrounding months' prices for context."}

    Format requirements:
    1. The output result for each prompt should not exceed 700 characters.
    2. Numbers, dates, etc. should be in emphasized markdown format.
    3. Before each paragraph, please use bullet point character ✦.
    4. Respond in JS0N withthese keys and values:
    - question:question
    - answer:The result based on the corresponding prompt of the question.`,

  TxRateChange: `According to the data given to you, output the results separately according to the following prompts.

    {“question":"How has the lowest price changed over time?”,”prompt":"Analyze and summarize the changes in the lowest prices from the provided dataset over the entire period, highlighting key fluctuations and potential causes for these changes.”},{“question":"How does the average price compare to the lowest?”,”prompt":"Compare the lowest prices to the average prices across the dataset. Identify any persistent trends and provide insights into the implications for the market.”},{“question":"What are the trends in prices from 2022 to 2024?”,”prompt":"Evaluate the overall trends in energy prices from 2022 to 2024, summarizing any significant increases or decreases and analyzing the factors driving these trends.”},{“question":"Which month recorded the highest average price?”,”prompt":"Identify which month had the highest average price in the data set and provide details on the price for that month, along with surrounding months' prices for context."}

    Format requirements:
    1. The output result for each prompt should not exceed 700 characters.
    2. Numbers, dates, etc. should be in emphasized markdown format.
    3. Before each paragraph, please use bullet point character ✦.
    4. Respond in JS0N withthese keys and values:
    - question:question
    - answer:The result based on the corresponding prompt of the question.`,

  TxMarketRateTrending: `According to the data given to you, output the results separately according to the following prompts.

    {"question":"What is the average price difference between REP and market?","prompt":"Calculate the average price difference between the REP's lowest price and the overall market average price for the provided timeframe. Summarize any implications of this difference."},{"question":"How do our average rates compare to competitors’?","prompt":"Compare the average prices of the REP with the market's average prices. Identify trends, advantages, or disadvantages in pricing relative to competitors' offerings."},{"question":"When did the lowest price hit its peak?","prompt":"Identify the date when the lowest price reached its highest value within the dataset. Provide details on the price and surrounding trends for context."},{"question":"What were the average prices for the first two weeks?","prompt":"Summarize the average energy prices for the first two weeks of July 2024. Highlight any significant increases or decreases and analyze potential reasons for these trends."},

    Format requirements:
    1. The output result for each prompt should not exceed 700 characters.
    2. Numbers, dates, etc. should be in emphasized markdown format.
    3. Before each paragraph, please use bullet point character ✦.
    4. Respond in JS0N withthese keys and values:
    - question:question
    - answer:The result based on the corresponding prompt of the question.`,

  TxMarketLowestRatePlans: `According to the data given to you, output the results separately according to the following prompts.

    {"question":"How do average prices compare across different usage levels?","prompt":"Compare the average prices for 500 kWh, 1000 kWh, and 2000 kWh from the dataset. Summarize the differences and discuss the implications for consumers."},{"question":"Which plan has the lowest base rate available?","prompt":"Determine which plan has the lowest base rate from the provided data. Provide details regarding the plan name, provider, and any relevant pricing information.
    "},{"question":"How does the lowest price change over time?","prompt":"Evaluate the changes in the lowest price over the provided time frame. Highlight key dates of price changes and any observed trends related to market conditions."},{"question":"Which provider offers the best rate for 2000 kWh?","prompt":"Identify the provider that offers the lowest rate for 2000 kWh. Include details about the plan and any competitive advantages it may have."},{"question":"How do trends in the lowest prices compare?","prompt":"Analyze the trends in the lowest prices across the three usage levels (500 kWh, 1000 kWh, 2000 kWh). Summarize any significant observations and potential market implications."},

    Format requirements:
    1. The output result for each prompt should not exceed 700 characters.
    2. Numbers, dates, etc. should be in emphasized markdown format.
    3. Before each paragraph, please use bullet point character ✦.
    4. Respond in JS0N withthese keys and values:
    - question:question
    - answer:The result based on the corresponding prompt of the question.`,

  dashboard: `Here is the latest data for Utility rates across various states in the United States:

    Data definitions for each Utility in a state:
    - provider_count: The number of providers offering plans for this specific Utility
    - plan_count: The number of plans available for this specific Utility (from all providers combined)
    - lowest_rate: The lowest available rate for this specific Utility
    - average_rate: The average rate for this specific Utility
    - your_rate: [Rep name]'s lowest rate plan for this specific Utility
    - rank1_plan: The provider and plan with the lowest rate for this specific Utility

    Note: If there is no rank data for a Utility, it means [Rep name] does not currently offer plans in that market.

    As the market intelligence dashboard for operations team, provide a concise single-paragraph summary (3-4 sentences) that highlights:
    -  [Rep name]'s overall market position compared to lowest rates (% difference)
    - Market presence overview (which utilities [Rep name] serve, [Rep name]'s plan counts per utility)
    - Most notable competitive positions (best/worst rankings by utility)
    - Key competitive threats or advantages

    Use specific numbers, keep insights direct, and maintain a professional, internal-facing tone. Focus on actionable insights. Numbers, dates, etc. should be in emphasized markdown format.`,
};
