import { ResponsiveLine } from "@nivo/line";
import {
  ChartBlock,
  ChartTitle,
  ChartWrapper,
  CustomTooltip,
  CustomTooltipX,
  CustomTooltipY,
  CustomTooltipYLabel,
  CustomTooltipYVal,
} from "../Styles/Chart.styled";
import { BaseChartProps } from "../../common/chart-config";
import { DataSeriesTypes } from "../../types";
import { NoResult } from "../Common/NoResult";
import { getMaxChartValue, getMinChartValue } from "../../common/functions";
import { mergeObjects } from "./helper";
import moment from "moment";
import Loader from "../Common/Loader";

interface ChartProps {
  title?: string;
  data: DataSeriesTypes[];
  tooltipExtraContent?: any;
  isLoading?: boolean;
}

export const PriceChart: React.FC<ChartProps> = ({
  title,
  data,
  tooltipExtraContent,
  isLoading = false,
}) => {
  let isMobile = document.body.clientWidth <= 786;

  const dataSize = data?.[0]?.data?.length || 0;

  const chatConfig = {
    yFormat: (val: number) => val + "¢",
    axisBottom: {
      tickValues: isMobile
        ? "every 6 months"
        : `every ${dataSize > 12 ? 2 : 1} months`,
      format: "%b %Y",
    },
    axisLeft: {
      legend: "Rate/kWh (in cents)",
    },
    margin: {
      bottom: 100,
    },
    sliceTooltip: ({ slice }: { slice: any }) => {
      if (slice) {
        const { points } = slice || {};
        if (points) {
          const xVal = points[0]?.data?.xFormatted || "";
          const xValFormatted = xVal
            ? moment(xVal, "MM/DD/YYYY").format("MMM YYYY")
            : "";

          let findDetail = [];
          if (tooltipExtraContent) {
            findDetail = tooltipExtraContent.find((item: any) => {
              return (
                moment(item.date, "YYYY-MM").format("MM/DD/YYYY") ===
                points?.[0]?.data?.xFormatted
              );
            });
          }

          return (
            <CustomTooltip>
              <CustomTooltipX>{xValFormatted}</CustomTooltipX>
              {points.map((val: any, idx: number) => (
                <CustomTooltipY key={idx}>
                  <CustomTooltipYLabel color={val.serieColor}>
                    <i />
                    <span>{val.serieId}</span>
                  </CustomTooltipYLabel>
                  <CustomTooltipYVal>{val.data.yFormatted}</CustomTooltipYVal>
                </CustomTooltipY>
              ))}
              {findDetail?.[0] && <TooltipExtraContent data={findDetail} />}
            </CustomTooltip>
          );
        }
      }
      return null;
    },
  };

  const maxVal = getMaxChartValue(data || []);
  const minVal = getMinChartValue(data || []);
  const newConfig = mergeObjects(BaseChartProps, chatConfig);

  return (
    <ChartBlock>
      {title && <ChartTitle>{title}</ChartTitle>}
      <ChartWrapper>
        {isLoading ? (
          <Loader />
        ) : data?.length === 0 ? (
          <NoResult />
        ) : (
          <ResponsiveLine
            {...newConfig}
            data={data}
            yScale={{
              type: "linear",
              min: minVal,
              max: maxVal,
            }}
          />
        )}
      </ChartWrapper>
    </ChartBlock>
  );
};

const TooltipExtraContent = ({ data }: { data: any }) => {
  return (
    <>
      <h6
        style={{
          fontWeight: 500,
          marginTop: 10,
          fontSize: 12,
          marginBottom: 6,
          color: "#999",
        }}
      >
        Breakdown:
      </h6>
      <p style={{ margin: "2px 0", fontSize: 13 }}>
        {`Rate for ${data?.first_kwh_start}-${data?.first_kwh_end} kWh: `}
        <b>{`${data?.first_standard_rate}¢`}</b>
      </p>
      <p style={{ margin: "2px 0", fontSize: 13 }}>
        {`Rate for usage above ${data?.first_kwh_end} kWh: `}
        <b>{`${data?.over_standard_rate}¢`}</b>
      </p>
      {data?.other_charge_ptc?.map((item: any, idx: number) => (
        <p style={{ margin: "2px 0", fontSize: 13 }} key={idx}>
          {`${item.title}: `}
          <b>{`${item.value}¢`}</b>
        </p>
      ))}
      <p
        style={{
          fontSize: 12,
          margin: "16px 0 0",
        }}
      >
        PTC rate is estimated from average residential electricity use.
      </p>
    </>
  );
};
