import React from 'react';
import { Amplify } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import awsExports from '../../aws-exports'
import { useHistory } from 'react-router-dom'
import ParticlesAuth from './ParticlesAuth';
Amplify.configure(awsExports)

const Login = () => {
    document.title = "Login - PowerRateIndex";
    const history = useHistory()

    return (
        <React.Fragment>
            <ParticlesAuth>
                <Authenticator loginMechanisms={['email']} hideSignUp={true}>
                    {({ signOut, user }) => {
                        history.push('/dashboard')
                        return <main></main>
                    }}
                </Authenticator>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default Login;