export const fetchAiSummaryResult = async (content, prompt) => {
  try {
    const formdata = new FormData();
    formdata.append("content", content);
    formdata.append("prompt", prompt);

    const requestOptions = {
      method: "POST",
      // headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    const url = `https://aa.findme365.com/api/v1/pri_gpt`;
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      return null;
    }

    const result = await response.json();
    
    return result?.choices[0]?.message?.content || null;
  } catch (error) {
    console.log(error);
    return null;
  }
};
