import { api } from "../config";
import { getToken } from "./common";

export const fetchTxDashboard = async (term) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  if (term) {
    formdata.append("term", term);
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}Dashboard/tx_dashboard`;
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  return { ...data, stateId: "TX" };
};

export const fetchUsnDashboard = async (stateId, term, type) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  formdata.append("state_id", stateId);
  formdata.append("service_type", type || "1");
  if (term) {
    formdata.append("term", term);
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}Dashboard/usn_dashboard`;
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  return {
    ...data,
    stateId,
  };
};

export const exportTxDashboard = async (term) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  if (term) {
    formdata.append("term", term);
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}Dashboard/export_tx_dashboard`;
  const response = await fetch(url, requestOptions);
  return response;
};

export const exportUsnDashboard = async (stateId, term, type) => {
  const newToken = await getToken();
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${newToken}`);

  const formdata = new FormData();
  formdata.append("state_id", stateId);
  formdata.append("service_type", type || "1");
  if (term) {
    formdata.append("term", term);
  }

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  const url = `${api.API_URL}Dashboard/export_usn_dashboard`;
  const response = await fetch(url, requestOptions);
  return response;
};
