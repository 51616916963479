import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "../styles/dashboard.css";
import Loader from "../Components/Common/Loader";
import { useSelector } from "react-redux";
import { selectStateList } from "../store/location/selector";
import { fetchTxDashboard, fetchUsnDashboard } from "../api/dashboard";
import StateSection from "../Components/Dashboard/StateSection";
import { MySummaryMini } from "../Components/MySummaryMini";
import Summary from "../Components/Dashboard/Summary";
import { Container } from "reactstrap";

const Dashboard = () => {
  const stateList = useSelector(selectStateList);
  const [stateData, setStateData] = useState<any>(null);
  const [dataString, setDataString] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!stateList) return;

    const fetchAllData = async () => {
      const promises = stateList.map((state: any) => {
        if (state.state_id === "TX") {
          return fetchTxDashboard("");
        } else {
          return fetchUsnDashboard(state?.state_id, "");
        }
      });

      const results = await Promise.all(promises);
      const newStateData: any = {};

      results.forEach(({ response, stateId }) => {
        if (response) {
          newStateData[stateId] = {
            data: response?.[0] ? response : [],
            loading: false,
            term: "",
          };
        }
      });
      setStateData((prev: any) => ({ ...prev, ...newStateData }));
      setLoading(false);
    };

    setLoading(true);
    setStateData(null);
    fetchAllData();
  }, [stateList]);

  const fetchData = async (stateId: string, term: string) => {
    setStateData((prev: any) => ({
      ...prev,
      [stateId]: { data: [], loading: true, term: term },
    }));
    const result =
      stateId === "TX"
        ? await fetchTxDashboard(term)
        : await fetchUsnDashboard(stateId, term);
    setStateData((prev: any) => ({
      ...prev,
      [stateId]: {
        data: result?.response?.[0] ? result.response : [],
        loading: false,
        term: term,
      },
    }));
  };

  useEffect(() => {
    if (!stateData) return;

    let str = "";
    for (let i in stateData) {
      if (stateData[i]) {
        const state_name = stateData[i]?.data?.[0]?.state_name || "";
        str += `##${state_name}\n${JSON.stringify(stateData[i].data)}\n`;
      }
    }

    setDataString(str);
  }, [stateData]);

  return (
    <div className="page-content">
      <Helmet>
        <title>PowerRateIndex</title>
      </Helmet>
      <Container style={{ maxWidth: "1440px" }}>
        <MySummaryMini />
        {loading && <Loader />}
        {dataString && <Summary data={dataString} section="dashboard" />}
        {stateData &&
          Object.keys(stateData).map((stateId) => (
            <StateSection
              key={stateId}
              stateId={stateId}
              data={stateData[stateId].data}
              loading={stateData[stateId].loading}
              handleRequestData={(stateId: string, term: string) =>
                fetchData(stateId, term)
              }
            />
          ))}
      </Container>
    </div>
  );
};

export default Dashboard;
