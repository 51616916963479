import Loader from "./Common/Loader";
import { getLogoSrc } from "../helpers/func";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useAuth } from "./Hooks/UserHooks";
import { selectMyProvider } from "../store/myProvider/selector";

export const MySummaryMini = () => {
  const currentLocation = useSelector<
    any,
    { state_id: string; state_name: string }
  >((state) => state.Location.currentLocation);
  const myProvider = useSelector(selectMyProvider);
  const [userName, setUserName] = useState("");

  const { userProfile } = useAuth();

  useEffect(() => {
    if (userProfile && (userProfile as any).attributes) {
      const { email } = (userProfile as any).attributes;
      setUserName(email);
    }
  }, [userProfile]);

  if (!myProvider) return null;

  return (
    <div className="card crm-widget">
      <div className="card-body p-0">
        <div className="d-flex align-items-center justify-content-between py-3 px-4 pe-2">
          <div>
            <h5 className="text-uppercase fs-19 mb-1">
              Welcome back{userName ? `, ${userName.split("@")?.[0]}` : ""}
            </h5>
            <p className="text-muted mb-0">
              Your go-to tool for retail energy pricing and competitive insights
            </p>
          </div>
          <div>
            {myProvider ? (
              <img
                src={getLogoSrc(
                  myProvider.provider_id,
                  currentLocation?.state_id
                )}
                alt=""
                height="60"
              />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
